import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { Link } from 'react-router-dom'
import tinycolor from 'tinycolor2'

import { getApiBase } from './../../../helpers'
import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'

import { ReactComponent as ArrowMinimal } from './../../../../assets/icons/arrowminimal.svg'

const CaseThumb = (props) => {
	const [showOverlay, setShowOverlay] = useState(false)

	return (
		<div className={`col col4 thumb col33-l col8-s ${props.showCases ? 'show' : ''}`}>
			<div className="inner">
				<img className="brandlogo" src={props.data.logo ? props.data.logo.sizes.medium_large : ''} onMouseEnter={() => setShowOverlay(true)} />
				{showOverlay ?
					<div className={`overlay ${tinycolor(props.data.brandcolor ? props.data.brandcolor : '#000').isLight() ? 'bright' : 'dark'}`} style={{ backgroundColor: `${props.data.brandcolor}` }} onMouseLeave={() => setShowOverlay(false)}>
						<div className="name">{props.data.post_title}</div>
						{props.data.alumni ? <div className="alumni">Alumni</div> : null}
						<div className="description">{props.data.description}</div>
						{props.data.link ? <a href={props.data.link} target="_blank"><ArrowMinimal /></a> : null}
					</div>
					: null}
			</div>
		</div>
	)
}

function CasesAllModule(props) {
	const [cases, setCases] = useState([]);
	const [casesAreLoading, setCasesAreLoading] = useState(false);
	const [showCases, setShowCases] = useState(false);
	const [areaCurrent, setAreaCurrent] = useState();
	const [fundCurrent, setFundCurrent] = useState();
	const [totalNumOfPosts, setTotalNumOfPosts] = useState(0);
	const [fetchingCases, setFetchingCases] = useState(false);

	const loadmore = () => {
		fetchData(true);
	}

	const fetchData = async (loadmore) => {
		setFetchingCases(true)
		const area = props.query.area ? props.query.area : '';
		var fund = props.query.fund ? props.query.fund : '';

		// If no fund is set, default to the first available fund
		if (!fund && props.archivePosts.tax.fund.length > 0) {
			fund = props.archivePosts.tax.fund[0].slug;
		}

		setFundCurrent(fund != '' ? props.archivePosts.tax.fund.find(ele => ele.slug == fund).name : 'All');

		let offset = loadmore ? cases.length : 0;
		const url = getApiBase() + '/wp-json/data/v2/getcases?area=' + area + '&fund=' + fund + '&offset=' + offset;
		const response = await fetch(url);
		const jsonResponse = await response.json();
		if (loadmore) setCases([...cases, ...jsonResponse.posts])
		else setCases(jsonResponse.posts)
		console.log(url)
		setCasesAreLoading(false);

		setTotalNumOfPosts(jsonResponse.totalNumOfPosts);
		setFetchingCases(false);

		setTimeout(() => {
			setShowCases(true);
		}, 20)
	}

	useEffect(() => {
		if (props.slug == props.location) {
			setCasesAreLoading(true);
			setShowCases(false);
			setCases([])
			fetchData(false)
		}
	}, [props.query])

	const SkeletonLoader = [];


	//! ÆNDRET HERFRA
	const setHeight = (e) => {
		e.target.closest('.PageModule').querySelector('.thumblist').classList.add('set-height')
		e.persist()
		setTimeout(() => {
			e.target.closest('.PageModule').querySelector('.thumblist').classList.remove('set-height');
		}, 500);
	}
	//! ÆNDRET HERTIL

	for (let i = 0; i < 8; i++) {
		SkeletonLoader.push(<div key={i} className="col col4 thumb col8-s skeleton" kye={i}><div className="inner" /></div>)
	}
	/* Buttons */
	const goTo = (<a href="/portfolio"><div className="btn small black">Curious to see the whole portfolio?</div></a>);

	return (
		<div className="PageModule CasesAllModule">
			<div className="archivefilter">
				<div className="col col16">
					<div className="inner">
						<div className="filter-name">Investments</div>
						<ul className="filter-list">
							{/* Commented out "All" category */}
							{/* <li className={`${props.query.fund == null ? 'selected' : ''}`} onClick={(e) => { setHeight(e) }}>
								<Link to={`${props.query.area ? '?area=' + props.query.area : props.slug}`}>All</Link>
							</li> */}
							
							{props.archivePosts.tax.fund.map((ele, i) => (
								<li key={i} 
									onClick={(e) => { setHeight(e) }} 
									className={`${props.query.fund == ele.slug || (!props.query.fund && i === 0) ? 'selected' : ''}`}>
									<Link to={`?fund=${ele.slug}${props.query.area ? '&area=' + props.query.area : ''}`}>
										{ele.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="col col16 breaker">
				<div className="inner" />
			</div>
			<div className="inner thumblist">
				{casesAreLoading ?
					null
					:
					cases.length > 0 ? cases.map((ele, i) => (
						<CaseThumb key={i} data={ele} showCases={showCases} />
					)) : <div className="noentries">Sorry, nothing found.</div>
				}
			</div>

			{props.slug === '/' || props.slug == '/forside-placeholder' ?

				<div className="col col16 loadmore-container">
					<div className='separator'></div>
					{goTo}
				</div>
				: totalNumOfPosts > cases.length && showCases ?
					<div className="col col16 loadmore-container">
						<div className="col col16 loadmore-container">
							<div className={`btn small black ${fetchingCases ? 'loading' : ''}`} onClick={fetchingCases ? null : loadmore}>Load more</div>
						</div>
					</div>
					: null}
		</div>
	);
}

export default connect((state) => ({
	query: state.router.location.query,
	location: state.router.location.pathname
}))(CasesAllModule);
