import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import './index.scss';

function NewWeKnowModule(props) {
	const [statements, setStatements] = useState([]);
	const [activeNum, setActiveNum] = useState(null);
	const [counter, setCounter] = useState();
	const [timer, setTimer] = useState();

	const activeRef = useRef(activeNum);
	activeRef.current = activeNum;

	const statementsRef = useRef(statements);
	statementsRef.current = statements;

	const timerRef = useRef(timer);
	timerRef.current = timer;

	useEffect(() => {
		clearTimeout(timerRef.current);
		setActiveNum(null);

		let statementsNew = [];
		for (let i = 0; i < props.data.statements.length; i++) {
			let statement = [];
			for (let i2 = 0; i2 < props.data.statements[i].statement.length; i2++) {
				let letter = props.data.statements[i].statement.substring(i2, i2 + 1);
				statement.push(letter);
			}
			statementsNew.push(statement)
		}

		setStatements(statementsNew);

		setTimeout(() => {
			startTimer();
		}, 200);

	}, [props.data.statements])

	const startTimer = () => {
		advanceTimer();
		setActiveStatement(0);
	}

	const setActiveStatement = (num) => {
		setActiveNum(num);
	}

	const advanceTimer = () => {
		let t = setTimeout(() => {
			setActiveStatement((activeRef.current + 1) % statementsRef.current.length);
			advanceTimer()
		}, 1700)

		setTimer(t);
	}

	return (
		<div className="PageModule NewWeKnowModule">
			<div className="hero col col16">
				<div className='statements-container'>
					<div className="statement-wrapper">
						<h1 className="weknow">{props.data.catchphrase}</h1>
						{statements.map((ele, i) => (
							<h1 className={`statement ${activeNum == i ? 'active' : ''}`} key={i}>
								{ele.map((letter, i2) => (
									<span key={i2} className={letter == ' ' ? 'whitespace' : ''}>{letter}</span>
								))}
							</h1>
						))}
					</div>
				</div>
				<img src={`${props.data.image['url']}`} />
			</div>
		</div>
	);
}

export default connect()(NewWeKnowModule);
