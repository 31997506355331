import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import {animateScroll} from './../../../actions';

const breakPoint = 700;

function CodexModule(props) {
	const [activeSection, setActiveSection] = useState(null);
	const [shouldBreak, setShouldBreak] = useState(false)

	const topOffset = 150, topOffsetMob = 80;

	useEffect(() => {
		setShouldBreak(props.screenWidth <= breakPoint);
	}, [props.screenWidth])

	useEffect(() => {
		if (props.scrollPosition > 0) {
			const sections = document.getElementsByClassName('section');
			let activeSection = null;
			for (let i = 0; i < sections.length; i++) {
				let pos = sections[i].getBoundingClientRect().y - (shouldBreak ? topOffsetMob : topOffset) - 1;
				if (pos <= 0) activeSection = i;
			}
			setActiveSection(activeSection)
		}
	}, [props.scrollPosition])

	useEffect(() => {
		setActiveSection(null)
	}, [])

	const linkClickHandler = (e) => {
		e.preventDefault();
		const targetLink = e.target.closest('a');
		if (!targetLink) return;
		const targetId = targetLink.getAttribute('href').substring(1,99);
		const ele = document.getElementById(targetId);
		const scrollDest = ele.getBoundingClientRect().y + window.pageYOffset - (shouldBreak ? topOffsetMob : topOffset);
		animateScroll(scrollDest);
	}

  return (
    <div className="PageModule CodexModule">
    	<div className="col col8 nav col16-mobxl">
    		<div className="sticky" style={{top: shouldBreak ? topOffsetMob : topOffset}}>
    			{props.data.section.map((ele, i) => (
    					<a href={`#section-${ele.title.toLowerCase()}`} onClick={linkClickHandler}><h2 className={`link ${i == activeSection ? 'active' : ''}`}>{ele.title}</h2></a>
    				))}
	    	</div>
    	</div>
    	<div className="col col8 col16-mobxl">
			{props.data.section.map((ele, i) => (
				<div className="section" id={`section-${ele.title.toLowerCase()}`}>
					<h2 className="title">{ele.title}</h2>
					<div className="content" dangerouslySetInnerHTML={{__html: ele.text}}/>
				</div>
				))}
    	</div>
    </div>
  );
}

export default connect(state => ({
	scrollPosition: state.App.scrollPosition,
	screenWidth: state.Device.size.width
}))(CodexModule);
