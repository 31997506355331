import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom';
import './index.scss';

function NoMatch(props) {
	useEffect(() => {
		props.dispatch({type: 'NO_PAGE_FOUND'})
	}, [])

  return (
    <div className="nomatch">
    	404
    	<Redirect to="/" status={404}/>
    </div>
  );
}

export default connect()(NoMatch);
