import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; // Import withRouter
import './index.scss';
import Popup from '../../../PopupModules/Popup';


const resizeHandler = () => {
    let isMobileSize = window.innerWidth <= 500 ? true : false;
    return isMobileSize;
}
const ipadSize = () => {
    let isIpad = window.innerWidth > 500 && window.innerWidth <= 1000 ? true : false;
    return isIpad;
}

let throttle;

function NewPopupMenu(props) {
    const [menuPoint, setMenuPoint] = useState("");
    const [popup, setPopup] = useState([]);
    const [popupContent, setPopupContent] = useState([]);
    const [isMobile, setIsMobile] = useState();
    const [currentElement, setCurrentElement] = useState('');
    const [title, setTitle] = useState('');
    const [popupData, setPopupData] = useState([]);


    clearTimeout(throttle);
    throttle = setTimeout(() => {
        window.addEventListener("resize", () => {
            setIsMobile(resizeHandler())
        });
    }, 1000 / 60);

    useEffect(() => {
        setIsMobile(resizeHandler());

        // Cleanup function to reset the popup state
        return () => {
            setMenuPoint("");
            setPopup([]);
            setPopupContent([]);
            setCurrentElement('');
            setTitle('');
            setPopupData([]);
        };
    }, []);

    useEffect(() => {
        const unlisten = props.history.listen(() => {
            setMenuPoint("");
            setPopup([]);
            setPopupContent([]);
            setCurrentElement('');
            setTitle('');
            setPopupData([]);
        });

        return () => {
            unlisten();
        };
    }, [props.history]);

    const handleOpacity = (title) => {
        const popup = document.querySelector('.popup-wrapper');
        const popupunder = document.querySelector('.popunder');
        const popupunderWrapper = document.querySelector('.popunder-wrapper');
        if (!menuPoint) {
            console.log('not opened')
            popup ? popup.classList.remove('hide') : console.log(); //remove hide
            popupunder.classList.remove('closed');
            setTimeout(() => {
                popupunder.classList.remove('opacity0');//remove open på popunder-wrapper
                popupunderWrapper.classList.remove('fade-out'); //Remove close på begge elementer
            }, 300);

        } else if (menuPoint === title) {
            // console.log('opened with same content')
            popupunderWrapper.classList.add('fade-out');//add close på begge elementer
            popupunder.classList.add('closed');
            setTimeout(() => { //setTimeOut 500
                popup ? popup.classList.add('hide') : console.log();//add hide
                popupunder.classList.add('opacity0');//remove open på popunder-wrapper
            }, 500);
        } else {
            // console.log('opened with different content')
            if (popup) {
                popupunder.classList.add('hide')
                popupunder.classList.add('opacity0');//remove open på popunder-wrapper
                popupunderWrapper.classList.add('fade-out');//add close på popunder-wrapper
                setTimeout(() => { //setTimeOut 500
                    popupunder.classList.remove('hide')
                    popupunder.classList.remove('opacity0');//remove open på popunder-wrapper
                }, 200);
                setTimeout(() => { //setTimeOut 500
                    popupunderWrapper.classList.remove('fade-out'); //remove close på popunder-wrapper
                }, 500);
            }
        }
    }

    const popunderHandler = (point, e, close) => {
        if (popup) {
            menuPoint == point.title ? setMenuPoint('') : setMenuPoint(point.title);
            setTitle(point.title);
            !isMobile ? handleOpacity(point.title) : console.log();
            setPopupContent(point);
            setPopup(point.popup);
            setCurrentElement(e.closest('.point-wrapper'))
            const size = ipadSize();
            let selector;
            selector = size ? '.popunder' : '#' + e.closest('.point-wrapper').id;
            setTimeout(() => {
                if (close || menuPoint == point.title) {
                    document.querySelector('#' + e.closest('.point-wrapper').id).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                } else {
                    setTimeout(() => {
                        document.querySelector('.popunder').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 100);
                }
            }, 100);
        }
    }


    const popupElement = (<div className='popup-wrapper'>
        <Popup popup={popup} isMobile={isMobile} popunderHandler={popunderHandler} popupContent={popupContent} currentElement={currentElement} menuPoint={menuPoint} title={title} popupData={popupData} setPopupData={setPopupData} />
    </div>)

    const point = (
        <div className='popunder-menu col col16'>
            {props.data.heading ? (
                <div className='separator'>
                    <span className="bold">{props.data.heading_bold}</span> {props.data.heading}
                </div>
            ) : (
                <div className='separator'>
                    <span className="bold">PSV</span> The land of opportunities
                </div>
            )}
            {props.data.menu_points.map((point, i) => {
                return (
                    <div className='point-wrapper' key={point.title + i} id={`popup${point.popup[0].ID}`} >
                        <div className='grid'>
                            <img src={point.image['url']} className={`${point.image_overlay ? 'lay' : null}`} />
                            <div className={`overlay overlay-${point.image_overlay}`}></div>
                        </div>
                        <div className='hoverbox' onClick={(e) => { popunderHandler(point, e.target); }}>
                            <p className='underlined'>{point.title}</p>
                            <p className='grayed-text'>{point.text}</p>
                            {menuPoint === point.title ? null : <div className='horizontal'></div>}
                            {menuPoint === point.title ? <div className='vertical'></div> : null}
                        </div>

                        {isMobile && point.title === menuPoint ? popupElement : console.log()}
                        {/*  {isMobile ? popupElement : null} */}
                    </div >
                )
            })}
        </div >
    )

    return (
        <div className='PageModule NewPopupMenu'>
            {point}
            {/*   {isMobile ? null : title === menuPoint ? popupElement : null} */}
            {isMobile ? null : popupElement}
        </div >
    )
}

export default connect(state => ({
    screenWidth: state.Device.size.width
}))(withRouter(NewPopupMenu)); // Wrap with withRouter
