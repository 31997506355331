import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'

import './index.scss';

import ApiFetcher from './ApiFetcher'


import FrontPage from './../FrontPage'
import NormalPage from './../NormalPage'
import Single from './../Single'
import NoMatch from './../NoMatch'
import SidebarFetcher from './../SidebarFetcher'


function Routes(props) {
	console.log(props)
	//console.log(props.pageToShow) //the slug of requested page
	return (
		<div>
			<Switch>
				{props.routes.map((ele, id) => (
					// <Route key={id} exact={ele.slug == ''  ? true : false} path={'/'+ele.slug} component={ApiFetcher}/>
					<Route key={id} exact={ele.slug == 'people' ? false : true} path={'/' + ele.slug} component={ApiFetcher} />
				))}

				<Route path="*" component={NoMatch} />
			</Switch>
			{props.pageToShow ? props.pageToShow.component === 'normalpage' ? <NormalPage dataKey={props.pageToShow.key} /> :
				props.pageToShow.component === 'single' ? <Single dataKey={props.pageToShow.key} /> :
					null : null}
		</div>
	);
}



export default connect((state) => ({
	routes: state.Routes.routes,
	pageToShow: state.App.pagesToShow[0],
}))(Routes)