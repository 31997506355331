import {setDeviceToMobile, setDeviceSize} from './../actions/'
import MobileDetect from 'mobile-detect';
import {store} from './configureStore'

export const InitDeviceAndScreenListener = () => {
	_checkForDevicetype(store);
	window.addEventListener('resize', () => _checkForDevicetype(store));
}

const _checkForDevicetype = () => {
	let md = new MobileDetect(window.navigator.userAgent);
	setDeviceToMobile(md.mobile())
	store.dispatch(setDeviceSize(window.innerWidth, window.innerHeight))
}