import React, {useEffect, useState} from 'react';
import './index.scss';

function HubSpotForm(props) {
    useEffect(() => {
        setTimeout(() => {
            window.hbspt.forms.create({
                portalId: "5509946",
                formId: props.data.hubspot_form_id, // live
                target: '#hs_form'
            });
        }, 200)
    }, [])


  return (
    <div className={`PageModule HubSpotForm`}>
        <div className="col col12 formwrapper">
            <div id="hs_form" className="hs_form"/>
        </div>
    </div>
  );
}

export default HubSpotForm