import React from 'react';

import {Animate} from 'react-move'
import {connect} from 'react-redux'
import BezierEasing from 'bezier-easing'

const DoTheScroll = (props) => {
    if (props.scrollIsAnimating) window.scrollTo(0,props.scrollPos);
	return null;
}

function AnimatedScrollHandler(props) {
	var bezierCurve = BezierEasing(0.4,0,0.1,1);

  return (
      	<Animate
	    	start={{scrollPos: 0}}
		    update={{
		      scrollPos: [props.scrollDest],
		      timing: {duration: props.scrollIsAnimating ? 700 : 0}
		      // timing: {duration: props.scrollIsAnimating ? 300 : 0}
		    }}
		>
		    {({scrollPos}) => (
				<DoTheScroll scrollPos={scrollPos} scrollIsAnimating={props.scrollIsAnimating}/>
		    )}
	  </Animate>
  );
}

export default connect((state) => ({
	scrollDest: state.App.scrollDest,
	scrollIsAnimating: state.App.scrollIsAnimating
}))(AnimatedScrollHandler);
