const initialState = {
	pageIsLoading: true,
	pageData: null,
	pagesToShow: [],
	scrollhistory: [],
	currentRouterKey: null,
	scrollPosition: 0,
	scrollDest: null,
	scrollIsAnimating: false,
	modalIsOpen: false,
	mousePos: {x: window.innerWidth/2, y: window.innerHeight/2}
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'PAGE_IS_LOADING':
			return {
				...state,
				pageIsLoading: true,
			}
		break;

		case 'PAGE_HAS_LOADED':
			const currentPageKey = action.pageObj.url
			return {
				...state,
				pageIsLoading: false,
				pagesToShow: [{key: currentPageKey, component: action.pageObj.component}],
				pageData: {
					...state.pageData,
					[currentPageKey]: {
						component: action.pageObj.component,
						menuName: action.pageObj.menuname,
						data: action.pageObj.data
					}
				}
			}
		break;

		case 'NO_PAGE_FOUND':
			return {
				...state,
				pageIsLoading: false,
				pagesToShow: []
			}
		break;

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition
			}
		break;

		case 'SET_CURRENT_SCROLL':
			return {
				...state,
				currentRouterKey: action.nextRouterKey,
				scrollhistory: {
					...state.scrollhistory,
					[state.currentRouterKey]: {
						val: window.scrollY
					}
				}
			}
		break;

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}
		break;

		case 'SET_MODAL_IS_OPEN':
			return {
				...state,
				modalIsOpen: action.state
			}
		break;

		case 'SET_MOUSE_POS':
			return {
				...state,
				mousePos: action.mousePos
			}
		break;

		default:
			return state
	}
}

export default App