import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'

function UploadPitchForm(props) {
	useEffect(() => {
		window.hbspt.forms.create({
		    portalId: "5509946",
		    formId: "586cad89-73bb-4703-b833-c91360d4af6f",
		    target: '#pitchform_target_div'
		});
	}, [])

  return (
    <div className={`PageModule UploadPitchForm`}>
    	<div className="col col3"/>
    	<div className="col col10 col16-m">
    		<div id="pitchform_target_div" className="hs_form"/>
    	</div>
    	<div className="col col3"/>
    </div>
  );
}

export default connect()(UploadPitchForm);