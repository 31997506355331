const initialState = {
	isMobile: null,
	size: {
		width: window.innerWidth,
		height: window.innerHeight
	},
	mobileBreakpoint: 600,
	mobileBreakpointSmall: 500,
	mobileBreakpointXSmall: 350,
	isMobileSize: false,
	isMobileXSmallSize: false
}

const device = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_DEVICE_TO_MOBILE':
			return {
				...state,
				isMobile: action.isMobile
			}

		case 'SET_DEVICE_SIZE':
			let isMobileSize = action.width <= state.mobileBreakpoint ? true : false;
			let isMobileXSmallSize = action.width <= state.mobileBreakpointXSmall ? true : false;

			return {
				...state,
				isMobileSize: isMobileSize,
				isMobileXSmallSize: isMobileXSmallSize,
				size: {
					width: action.width,
					height: action.height
				}
			}
		default:
			return state
	}
}

export default device