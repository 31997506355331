import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { Link } from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

const breakPoint = 1050;

const NewsColText = ({ data }) => (
	<div className="data">
		<h3 className="headline">{data.headline}</h3>
		<div className="text" dangerouslySetInnerHTML={{ __html: data.text }} />
		{data.make_link == 'internal' ?
			<div className="link"><Link to={'/' + data.link.post_name}>{data.link_text}</Link></div>
			: data.make_link == 'external' ?
				<div className="link"><a target="_blank" href={data.external_url_link}>{data.link_text}</a></div>
				: null}
	</div>
)

function NewsModule(props) {
	const [shouldBreak, setShouldBreak] = useState(false)

	useEffect(() => {
		setShouldBreak(props.screenWidth <= breakPoint);
	}, [props.screenWidth])

	return (
		<div className={`PageModule NewsModule ${props.slug == '/' ? 'frontpage' : props.slug == '/forside-placeholder' ? 'frontpage' : ''}`}>
			{props.data.title ?
				<div className='col col16'>

					<div className='separator'>{props.data.title}</div>
				</div>
				: ''}
			<div className='flex'>
				<div className={`col col8 imgcol col16-l ${shouldBreak ? 'break' : ''}`}>
					<div className="inner">
						{props.data.news[0].image ?
							<LazyImageComponent height={props.data.news[0].image.height} width={props.data.news[0].image.width} url={props.data.news[0].image.sizes.fullscreen} link={props.data.news[0].make_link == 'internal' ? '/' + props.data.news[0].link.post_name : props.data.news[0].make_link == 'external' ? props.data.news[0].external_url_link : null} linktype={props.data.news[0].make_link} />
							: null}
						{shouldBreak ? <NewsColText data={props.data.news[0]} headline={props.data.news[0].headline} text={props.data.news[0].text} link={`/` + props.data.news[0].link.post_name} /> : null}
					</div>
				</div>
				<div className="col col4 imgcol col8-l">
					<div className="inner">
						{props.data.news[1].image ?
							<LazyImageComponent height={props.data.news[1].image.height} width={props.data.news[1].image.width} url={props.data.news[1].image.sizes.medium_large} link={props.data.news[1].make_link == 'internal' ? '/' + props.data.news[1].link.post_name : props.data.news[1].make_link == 'external' ? props.data.news[1].external_url_link : null} linktype={props.data.news[1].make_link} />
							: null}
					</div>
				</div>
				<div className="col col4 imgcol col8-l">
					<div className="inner">
						{props.data.news[2].image ?
							<LazyImageComponent height={props.data.news[2].image.height} width={props.data.news[2].image.width} url={props.data.news[2].image.sizes.medium_large} link={props.data.news[2].make_link == 'internal' ? '/' + props.data.news[2].link.post_name : props.data.news[2].make_link == 'external' ? props.data.news[2].external_url_link : null} linktype={props.data.news[2].make_link} />
							: null}
					</div>
				</div>
				{!shouldBreak ?
					<div className="col col8">
						<div className="inner">
							<NewsColText data={props.data.news[0]} headline={props.data.news[0].headline} text={props.data.news[0].text} link={`/` + props.data.news[0].link.post_name} />
						</div>
					</div>
					: null}
				<div className="col col4 col8-l">
					<div className="inner">
						<NewsColText data={props.data.news[1]} headline={props.data.news[1].headline} text={props.data.news[1].text} link={`/` + props.data.news[1].link.post_name} />
					</div>
				</div>
				<div className="col col4 col8-l">
					<div className="inner">
						<NewsColText data={props.data.news[2]} headline={props.data.news[2].headline} text={props.data.news[2].text} link={`/` + props.data.news[2].link.post_name} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default connect(state => ({
	screenWidth: state.Device.size.width
}))(NewsModule);
