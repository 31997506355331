import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

import {Link} from 'react-router-dom'

function BreakerWithMultipleImagesModule(props) {
  return (
    <div className={`PageModule BreakerWithMultipleImagesModule`}>
    	<div className="col col16 text">
    		<div className="inner">{props.data.text}</div>
    		{props.data.link ? <Link to={props.data.link[0].post_name} className="linkarrow"><ArrowIcon/></Link> : null}
		</div>
    	<div className="col col16 images">
    		<div className="inner">
    			{props.data.images.map((ele, i) => (
	    			<div className="image-wrapper" key={i}>
	    				<LazyImageComponent height={ele.height} width={ele.width} url={ele.sizes.medium_large} scale={1.2}/>
	    			</div>
    				))}
    		</div>
    	</div>
    </div>
  );
}

export default connect()(BreakerWithMultipleImagesModule);
