import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

function SiteLoader(props) {
  return (
    props.pageIsLoading ? <div id="siteloader"/> : null
  );
}

export default connect(state => ({
	pageIsLoading: state.App.pageIsLoading
}))(SiteLoader);
