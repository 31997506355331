/* import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { getApiBase } from './../../../helpers'

import HeaderTextModule from './../../PageModules/HeaderTextModule'
import CTASplitterModule from './../../PageModules/CTASplitterModule'
import CTASplitterSmallModule from './../../PageModules/CTASplitterSmallModule'
import CasesAllModule from './../../PageModules/CasesAllModule'
import CasesSelectedModule from './../../PageModules/CasesSelectedModule'
import PeopleAllModule from './../../PageModules/PeopleAllModule'
import PeopleSelectedModule from './../../PageModules/PeopleSelectedModule'
import TestimonialModule from './../../PageModules/TestimonialModule'
import TextAndImageModule from './../../PageModules/TextAndImageModule'
import NewsModule from './../../PageModules/NewsModule'
import FaqModule from './../../PageModules/FaqModule'
import WeKnowModule from './../../PageModules/WeKnowModule'
import NewWeKnowModule from './../../PageModules/NewModules/NewWeKnowModule'
import NewHeaderTextModule from './../../PageModules/NewModules/NewHeaderTextModule'
import NewSeparatorLogo from './../../PageModules/NewModules/NewSeparatorLogo'
import NewPopupMenu from './../../PageModules/NewModules/NewPopupMenu'
import CodexModule from './../../PageModules/CodexModule'
import PressModule from './../../PageModules/PressModule'
import UntitledModule from './../../PageModules/UntitledModule'
import ThoughtsAllModule from './../../PageModules/ThoughtsAllModule'
import UploadPitchModule from './../../PageModules/UploadPitchModule'
import ContactBigCTAButtonsModule from './../../PageModules/ContactBigCTAButtonsModule'
import BreakerWithMultipleImages from './../../PageModules/BreakerWithMultipleImages'
import PeopleStrategyAnimationModule from './../../PageModules/PeopleStrategyAnimationModule'
import HubSpotForm from './../../PageModules/HubSpotForm'
import ResourceArchieveAll from '../../PageModules/NewModules/ResourceArchieveAll';


function Popup(props) {
    const { popupContent, currentElement, popup, popupData, setPopupData, isMobile } = props;
    // let jsonResponse;

    const fetchData = async (element) => {
        let currentRoute = element.post_name;
        console.log(element.post_name)
        const results = [];
        const response = await fetch(getApiBase() + '/wp-json/data/v2/normalpage?slug=' + currentRoute);
        let jsonResponse = await response.json();
        results.push(jsonResponse);


        if (element.post_name === 'project-academy') {
            const academyResponse = await fetch(getApiBase() + '/academy/wp-json/data/v2/normalpage?slug=project-resources');
            const jsonAcademy = await academyResponse.json();
            results.push(jsonAcademy);
        }


        return results;
    }

    let academy = false;
    const content = popupData ? popupData.map((page, index) => {
        if (page.slug === 'project-academy' || page.slug === 'project-resources') {
            academy = true;
            const { pagemodules } = page;
            return (
                <div key={index}>
                    {pagemodules.map((pagemodule) => {
                        return (
                            <div className='inner' key={index}>
                                {pagemodule.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={index} data={pagemodule} num={index} /> :
                                    pagemodule.acf_fc_layout === 'resources_all' ? <ResourceArchieveAll key={index} data={page} num={index} popupData={popupData} /> : null}
                            </div>
                        )
                    })}
                </div>
            )
        }
        else {
            academy = false;
            return (<div className='border' key={index}>
                {page ? page.pagemodules.map((ele, i) => {
                    return (<div className='inner' key={i}>
                        {
                            ele.acf_fc_layout === 'header_text_new' ? <NewHeaderTextModule key={i} data={ele} num={i} /> :
                                ele.acf_fc_layout === 'resources_all' ? <ResourceArchieveAll key={i} data={ele} num={i} popupData={popupData} /> :
                                    ele.acf_fc_layout === 'cta_splitter' ? <CTASplitterModule key={i} data={ele} num={i} /> :
                                        ele.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={i} data={ele} num={i} /> :
                                            ele.acf_fc_layout === 'faq' ? <FaqModule key={i} data={ele} num={i} /> :
                                                ele.acf_fc_layout === 'news' ? <NewsModule key={i} data={ele} slug={props.dataKey} num={i} /> :
                                                    null
                        }
                    </div>)
                }) : null}
            </div>)
        }
    }) : null;

    useEffect(() => {
        setPopupData([]);
        Promise.all(popup.map(element => fetchData(element)))
            .then(responses => {
                responses.forEach(results => {
                    setPopupData(current => [...current, ...results]);
                })
            });
    }, [popup])

    const closeHandler = () => {
        props.popunderHandler(popupContent, currentElement, true);
    }

    return (
        <div className={`popunder open ${!isMobile ? 'closed' : ''}`}>
            <div className={`popunder-wrapper col col16 fade-in ${!isMobile ? 'fade-out opacity0' : ''}`}>{academy ? <div className='border wrapper'>{content}</div> : <div className='wrapper'>{content}</div>}
            </div>
            {content ? <p className='close col col16' onClick={closeHandler}>Close</p> : null}
        </div>
    )
}

export default Popup; */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { getApiBase } from './../../../helpers'


import CTASplitterModule from './../../PageModules/CTASplitterModule'
import TextAndImageModule from './../../PageModules/TextAndImageModule'
import NewsModule from './../../PageModules/NewsModule'
import FaqModule from './../../PageModules/FaqModule'
import NewHeaderTextModule from './../../PageModules/NewModules/NewHeaderTextModule'
import ResourceArchieveAll from '../../PageModules/NewModules/ResourceArchieveAll';


function Popup(props) {
    const { popupContent, currentElement, popup, popupData, setPopupData, isMobile } = props;
    // let jsonResponse;

    const fetchData = async (element) => {
        let currentRoute = element.post_name;
        const results = [];
        const response = await fetch(getApiBase() + '/wp-json/data/v2/getprojects?slug=' + currentRoute);
        let jsonResponse = await response.json();
        results.push(jsonResponse);


        if (element.post_name === 'project-academy') {
            const academyResponse = await fetch(getApiBase() + '/academy/wp-json/data/v2/normalpage?slug=project-resources');
            const jsonAcademy = await academyResponse.json();
            results.push(jsonAcademy);
        }

        return results;
    }

    let academy = false;
    const content = popupData ? popupData.map((page, index) => {
        if (page.slug === 'project-academy' || page.slug === 'project-resources') {
            academy = true;
            const { pagemodules } = page;
            return (
                <div key={index}>
                    {pagemodules.map((pagemodule) => {
                        return (
                            <div className='inner' key={index}>
                                {pagemodule.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={index} data={pagemodule} num={index} /> :
                                    pagemodule.acf_fc_layout === 'resources_all' ? <ResourceArchieveAll key={index} data={page} num={index} popupData={popupData} /> : null}
                            </div>
                        )
                    })}
                </div>
            )
        }
        else {
            academy = false;
            return (<div className='border' key={index}>
                {page ? page.pagemodules.map((ele, i) => {
                    return (<div className='inner' key={i}>
                        {
                            ele.acf_fc_layout === 'header_text_new' ? <NewHeaderTextModule key={i} data={ele} num={i} /> :
                                ele.acf_fc_layout === 'resources_all' ? <ResourceArchieveAll key={i} data={ele} num={i} popupData={popupData} /> :
                                    ele.acf_fc_layout === 'cta_splitter' ? <CTASplitterModule key={i} data={ele} num={i} /> :
                                        ele.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={i} data={ele} num={i} /> :
                                            ele.acf_fc_layout === 'faq' ? <FaqModule key={i} data={ele} num={i} /> :
                                                ele.acf_fc_layout === 'news' ? <NewsModule key={i} data={ele} slug={props.dataKey} num={i} /> :
                                                    null
                        }
                    </div>)
                }) : null}
            </div>)
        }
    }) : null;

    useEffect(() => {
        setPopupData([]);
        Promise.all(popup.map(element => fetchData(element)))
            .then(responses => {
                responses.forEach(results => {
                    setPopupData(current => [...current, ...results]);
                })
            });
    }, [popup])

    const closeHandler = () => {
        props.popunderHandler(popupContent, currentElement, true);
    }

    return (
        <div className={`popunder open ${!isMobile ? 'closed' : ''}`}>
            <div className={`popunder-wrapper col col16 fade-in ${!isMobile ? 'fade-out opacity0' : ''}`}>{academy ? <div className='border'>{content}</div> : <div className='wrapper'>{content}</div>}
            </div>
            {content ? <p className='close' onClick={closeHandler}>Close</p> : null}
        </div>
    )
}

export default Popup;