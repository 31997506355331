import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import {getApiBase} from './../../../helpers'
import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

function ThoughtsAllModule(props) {
	const [posts, setPosts] = useState([]);
	const [postsAreLoading, setPostsAreLoading] = useState(true);
	const [showPosts, setShowPosts] = useState(false);
	const [areaCurrent, setAreaCurrent] = useState();
	const [fundCurrent, setFundCurrent] = useState();
	const [totalNumOfPosts, setTotalNumOfPosts] = useState(0);
	const [fetchingPosts, setFetchingPosts] = useState(false);


	const loadmore = () => {
		fetchData(true);
	}

	const fetchData = async (loadmore) => {
		setFetchingPosts(true)
		const category = props.query.category ? props.query.category : '';

		// setAreaCurrent(fund != '' ? props.archivePosts.tax.area.find(ele => ele.slug == area).name : 'None');
		// setFundCurrent(fund != '' ? props.archivePosts.tax.fund.find(ele => ele.slug == fund).name : 'All');

		let offset = loadmore ? posts.length : 0;
		const url = getApiBase()+'/wp-json/data/v2/getthoughts?category='+ category+'&offset='+offset;
		// const url = getApiBase()+'/wp-json/data/v2/getthoughts?category='+ area+'&fund='+fund+'&offset='+offset;
		// const url = getApiBase()+'/wp-json/wp/v2/thoughts';
		const response = await fetch(url);
		const jsonResponse = await response.json();
		if (loadmore) setPosts([...posts, ...jsonResponse.posts])
		else setPosts(jsonResponse.posts)

		setPostsAreLoading(false);

		setTotalNumOfPosts(jsonResponse.totalNumOfPosts);
		setFetchingPosts(false);

		setTimeout(() => {
			setShowPosts(true);
		}, 20)
	}

	useEffect(() => {
		if (props.slug == props.location) {
			setPostsAreLoading(true);
			setShowPosts(false);
			setPosts([])
			fetchData(false)
		}
	}, [props.query])

	const SkeletonLoader = [];
	for (let i = 0; i < 8; i++) {
		SkeletonLoader.push(<div className="col col4 col8-s thumb skeleton" key={i}><div className="inner"/></div>)
	}

  return (
    <div className="PageModule ThoughtsAllModule">
    	<div className="archivefilter">
    		<div className="col col16">
		    	<div className="inner">
		    		<div className="filter-name">View</div>
		    		<ul className="list">
		    			<li className={`${props.query.category == null ? 'selected' : ''}`}><Link to={props.slug}>All</Link></li>
		    			{props.archivePosts.tax.category.map((ele, i) => (
		    				<li key={i} className={`${props.query.category == ele.slug ? 'selected' : ''}`}><Link to={`?category=${ele.slug}`}>{ele.name}</Link></li>
		    			))}
			    	</ul>
			    </div>
		    </div>
    	</div>
    	<div className="col col16 breaker">
            <div className="inner"/>
        </div>
		<div className="thumblist">
	    	 {postsAreLoading ? 
	    	 		SkeletonLoader
	    	 	:
		    	 	posts.length > 0 ? posts.map((ele, i) => (
				 		<div className={`col col4 thumb col8-s ${showPosts ? 'show' : ''}`} key={i}>
				 			<div className="inner">
								{ele.thumb_image ? <LazyImageComponent height={ele.thumb_image.height} width={ele.thumb_image.width} url={ele.thumb_image.sizes.medium_large} link={props.slug + `/` + ele.slug}/> : null}
				 			</div>
			 				<div className="title"><Link to={props.slug + `/` + ele.slug}>{ele.post_title}</Link></div>
			 				<ul className="tax">
			 					{ele.category ? ele.category.map((ele2, i2) => (
			 						<li key={i2}><Link to={`?category=${ele2.slug}`}>{ele2.name}</Link></li>
		 						)) : null}
		 					</ul>
		 					<Link to={props.slug + `/` + ele.slug} className="link"><ArrowIcon/></Link>
			 			</div>
			 		)
			 		) : 
			 		<div className="noentries">Sorry, nothing found.</div>
	    	 }
		</div>
    </div>
  );
}

export default connect((state) => ({
	query: state.router.location.query,
	location: state.router.location.pathname
}))(ThoughtsAllModule);
