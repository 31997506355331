import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { Link } from 'react-router-dom'
import ResourceArchiveSingle from './../ResourceArchiveSingle'

function ResourceArchiveAll(props) {
    const { popupData } = props;
    console.log(props)
    const [resources, setResources] = useState([]);

    useEffect(() => {
        props.popupData.map((ele) => {
            ele.pagemodules[0].acf_fc_layout === 'resources_all' ? setResources(ele) : console.log()
        })
    }, [popupData])
    return (
        resources.archiveResources ?
            <div className="PageModule ResourceArchiveAll">
                <div className="col col16 indicator">
                    <div className="inner">Resources</div>
                </div>
                {resources.archiveResources.posts.length > 0 ?
                    <div className="thumblist">
                        {resources.archiveResources.posts.map((post, i) => {
                            if (i <= 2) {
                                return <ResourceArchiveSingle key={i} data={post} />
                            }
                        })
                        }
                    </div>
                    :
                    <div className="col col16">
                    </div>
                }
            </div>
            : null
    );
}

export default connect((state, ownProps) => ({
    /*     archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories,
        query: state.router.location.query,
        archivePosts: state.App.pageData[ownProps.slug].data.archiveResources,
        currentRouterPage: state.router.location.query ? state.router.location.query.page ? state.router.location.query.page : null : null,
        currentRouterCat: state.router.location.query ? state.router.location.query.cat ? state.router.location.query.cat : null : null */
}))(ResourceArchiveAll);
