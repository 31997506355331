import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import {Link} from 'react-router-dom'
import './index.scss';

function LazyImageComponent({height, width, url, widthRatio, active, scale, link, linktype, className}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const heightRatio = height/width;
	const widthRatioToUse = widthRatio ? widthRatio : 100
	const scaleToUse = scale ? scale : 1;
	
	const styles = {
		paddingBottom: heightRatio * widthRatioToUse * scaleToUse+'%',
		width: widthRatioToUse * scaleToUse+'%'
	}
	
  return (
    	<div className={`${className ? className : ''} lazy-image-component ${isLoaded ? 'loaded' : 'lazyloading'} ${active ? 'active' : ''}`} style={styles}>
    		{link ? 
    			linktype == 'external' ? <a href={link} target="_blank"><img src={url} onLoad={() => setIsLoaded(true)}/></a>
    			: <Link to={link}><img src={url} onLoad={() => setIsLoaded(true)}/></Link>
    		: <img src={url} onLoad={() => setIsLoaded(true)}/>}
		</div>
  );
}

export default LazyImageComponent;
