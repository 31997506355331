const initialState = {
	globalsHasBeenSet: false,
	menuitems: [],
	mainmenuIsOpen: false,
}

const Globals = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_GLOBALS':
			return {
				...state,
				globalsHasBeenSet: true,
				menu: {
					mainmenu: action.data.menu_mainmenu,
					quicklinks: action.data.menu_quicklinks,
					cta_btn: action.data.button,
				},
				footer: {
					menu_col1: action.data.footer_menu_col1,
					menu_col2: action.data.footer_menu_col2,
					menu_col3: action.data.footer_menu_col3,
					menu_col4: action.data.footer_menu_col4,
					address: action.data.footer_address,
					newsletter: {
						above: action.data.footer_newsletter_text_above,
						below: action.data.footer_newsletter_text_below
					}
				},
				meta: {
					description_global: action.data.meta_description
				}
			}
		break;

		case 'TOGGLE_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: !state.mainmenuIsOpen
			}

		case 'CLOSE_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: false
			}

		default:
			return state
	}
}

export default Globals