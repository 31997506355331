import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

function FrontPage() {
  return (
    <div id="page-frontpage">
    	<h1>FrontPage</h1>
    </div>
  );
}

export default connect()(FrontPage);
