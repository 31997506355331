import {history} from './configureStore'

export const initHubspotTracking = () => {
	doHubspotTracking(history.location.pathname);

	history.listen(location => {
		doHubspotTracking(location.pathname);
	})
}

const doHubspotTracking = (path) => {
	var _hsq = window._hsq = window._hsq || [];
	  _hsq.push(['setPath', path]);
	  _hsq.push(['trackPageView']);
}