import React from 'react';
import { connect } from 'react-redux'
import './index.scss'

import { Link } from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'

function CTASplitterModule(props) {
  return (
    <div className="PageModule full CTASplitterModule">
      <div className="col col16">
        <div className="inner">
          <h1>{props.data.headline}</h1>
          <Link to={`/` + props.data.link.post_name} className="btn white cta">{props.data.btn_text}</Link>
        </div>
      </div>
    </div>
  );
}

export default connect()(CTASplitterModule);
