import React, { useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';

import { Link } from 'react-router-dom'



function NewSeparatorLogo(props) {
    return (
        props.data ?
            <div className={`PageModule NewSeparatorLogo mod${props.num}`}>
                <div className="col col16">
                    <div className="inner">
                        <p className='label'>{props.data.label}</p>
                        <div className='separator'></div>
                        <h1 className='logo'>{props.data.logo_text}<span> TM</span></h1>
                    </div>
                </div>
            </div>
            : null
    );
}

export default connect()(NewSeparatorLogo);
