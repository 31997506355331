import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as PlusIcon} from './../../../../assets/icons/plusicon.svg'

function PeopleThumb(props) {
	const [isActive, setIsActive] = useState(false);
	const [posVals, setPosVals] = useState(null)
	const [init, setInit] = useState(false)
	const [showEmail, setShowEmail] = useState(false)

	const generateRandomImagePos = () => {
		var randomValues = [];
		let noiseAmount = 150;
		let offset = 30;

		for (let i = 0; i < props.data.hoverImages.length; i++) {
			let noiseX = Math.random()-0.5;
			let noiseY = Math.random()-0.5;

			var values = {
				x: noiseX > 0 ? noiseX*noiseAmount+offset : noiseX*noiseAmount-offset,
				y: noiseY > 0 ? noiseY*noiseAmount+offset : noiseY*noiseAmount-offset,
			}
			randomValues.push(values);
		}
		setPosVals(randomValues);
		setInit(true);
	}

	const showImages = (val) => {
		generateRandomImagePos();
		setIsActive(true);
	}

  return (
	<div className="col peoplethumb">
	 	<div className="inner">
	 		<div className="images" onMouseEnter={() => props.data.hoverImages ? showImages(true) : null} onMouseLeave={() => setIsActive(false)}>
	 			<Link to={'/people/'+props.data.post_name}>
					{props.data.image ? <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.large}/> : null}
		 			<div className="addImages">
		 				{props.data.hoverImages ? props.data.hoverImages.map((ele, i) => {
		 					let isHorizontal = ele.width > ele.height;
		 					return(
		 						<img key={i} className={`${isHorizontal ? 'landscape' : 'portrait'} ${isActive ? 'active' : ''}`} src={ele.sizes.large} style={{top: `calc(50% + ${init ? posVals[i].y : 0}px)`, left: `calc(50% + ${init ? posVals[i].x : 0}px)`}}/>
		 					)}) : null}
		 			</div>
	  				<PlusIcon/>
				</Link>
			</div>
	 		<h3 className="name">{props.data.post_title}</h3>
	 		<div className="data">{props.data.title}</div>
	 		<div className="data">{props.data.phone}</div>
	 		{props.data.email ? <div className="data">{showEmail ? <a href={`mailto:${props.data.email}`}>{props.data.email}</a> : <div className="toggle" onMouseEnter={() => setShowEmail(true)}>write me an email</div>}</div> : null}
 		</div>
	</div>
  );
}

export default connect()(PeopleThumb);
