import React from 'react';
import './index.scss';

import Mainmenu from './../Mainmenu'
import Topmenu from './../Topmenu'
import Mainlogo from './../Mainlogo'

function Globals(props) {
  return (
	<div>
		<Topmenu/>
		<Mainmenu/>
		<Mainlogo/>
	</div>
  );
}

export default Globals;
