import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import {ReactComponent as PlusIcon} from './../../../../assets/icons/plusicon.svg'

const breakPoint = 900;

function SingleFaq(props) {
	const [open, setOpen] = useState(false);

	return (
		props.design == 'Collapsed' || props.shouldBreak ? 
			<div className={`col col16 single ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
	    		<div className="col3 indicator">{props.indicator}</div>
		    		<div className="col11">
		    			<div className="headline">{props.headline}</div>
		    			<div className="text" dangerouslySetInnerHTML={{__html:props.text}}/>
					</div>
					<div className={`col2 toggle ${open ? 'open' : ''}`}><PlusIcon/></div>
	    	</div>
    	: 
    		<div className="col col16 single">
	    		<div className="col2 indicator">{props.indicator}</div>
	    		<div className="col5">
	    			<div className="headline">{props.headline}</div>
				</div>
				<div className="col8">
	    			<div className="text"><div className="inner" dangerouslySetInnerHTML={{__html:props.text}}/></div>
				</div>
	    	</div>
    	)
}

function FaqModule(props) {
	const [shouldBreak, setShouldBreak] = useState(false)

	useEffect(() => {
		setShouldBreak(props.screenWidth <= breakPoint);
	}, [props.screenWidth])

  return (
    <div className={`PageModule FaqModule type-${!shouldBreak ? props.data.design.toLowerCase() : ''}`}>
    	<div className="col col16"><div className="inner">{props.data.title}</div></div>
    {props.data.rows ? props.data.rows.map((ele, i) => (
    		<SingleFaq key={i} indicator={ele.indicator} headline={ele.headline} text={ele.maintext} design={props.data.design} shouldBreak={shouldBreak}/>
    	)) : null}
    	{props.data.view_more_link ? 
    		<Link className="view-more-link btn black" to={'/'+props.data.view_more_link.post_name}>{props.data.view_more_label}</Link>
    		: null}
    </div>
  );
}

export default connect(state => ({
	screenWidth: state.Device.size.width
}))(FaqModule);
