import React, { useEffect, useState } from 'react';
import './index.scss';

import { getApiBase } from './../../helpers'
import { useParams, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { ThoughtSectionText } from './sections'
import { ThoughtSectionQuote } from './sections'
import Footer from './../Footer'

import { ReactComponent as ArrowIcon } from './../../../assets/icons/arrowswungicon.svg'

function Single(props) {
  // console.log(props.apiData.acf.meta_description)
  // console.log('HALLO')
  useEffect(() => {
    if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    else window.scrollTo(0, 0);
  }, [props.dataKey])

  return (
    <div className={`page single ${props.mainmenuIsOpen ? 'open' : ''}`}>
      {props.pathname !== '/' && props.pathname == props.dataKey ?
        <Helmet>
          <title>PSV ∙ {props.apiData.title.rendered}</title>
          <meta name="description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:image" content={props.apiData.acf.open_graph_image ? props.apiData.acf.open_graph_image : ''} />

        </Helmet>
        : null}

      <Link to="/thoughts" className="back arrow"><ArrowIcon /></Link>

      {props.apiData.acf.sections.map((ele, i) => (
        ele.acf_fc_layout == 'text' ? <ThoughtSectionText key={i} data={ele} />
          : ele.acf_fc_layout == 'quote' ? <ThoughtSectionQuote key={i} data={ele} />
            : null
      ))}

      <Footer />
    </div>
  );
}

export default connect((state, ownProps) => ({
  apiData: state.App.pageData[ownProps.dataKey].data[0],
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[state.router.location.key],
  routerAction: state.router.action,
  meta: state.Globals.meta
}))(Single);
