import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { getApiBase } from './../../helpers'

const fetchData = async (dispatch, match, routes) => {
	let currentRoute = routes.find(ele => '/' + ele.slug === match.path)
	console.log(getApiBase() + currentRoute.endpoint)
	const response = await fetch(getApiBase() + currentRoute.endpoint);
	const jsonResponse = await response.json();

	dispatch({
		type: 'PAGE_HAS_LOADED',
		pageObj: {
			url: match.url,
			component: currentRoute.component,
			data: jsonResponse
		}
	})
}

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

function ApiFetcher(props) {
	const receiveUrl = props.match.url;
	const prevUrl = usePrevious(receiveUrl);
	const [currentKey, setCurrentKey] = useState();

	useEffect(() => {
		if (receiveUrl !== prevUrl) {
			// set scrollposition for use with browserhistory
			props.dispatch({ type: 'SET_CURRENT_SCROLL', nextRouterKey: props.router.location.key })

			// set pageloader
			props.dispatch({ type: 'PAGE_IS_LOADING' })

			// fetch data
			fetchData(props.dispatch, props.match, props.routes)
		}
	}, [receiveUrl])

	return null;
}

export default connect(state => ({
	routes: state.Routes.routes,
	router: state.router
}))(ApiFetcher)