import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'
import {Link} from 'react-router-dom'

function ContactBigCTAButtonsModule(props) {
	const [showLink, setShowLink] = useState([]) 

	useEffect(() => {
		setShowLink(Array(props.data.buttons.length).fill(false))
	}, [])

	const updateArray = (i) => {
		setShowLink(showLink.map((ele, index) => index == i ? !ele : ele))
	}

  return (
    <div className={`PageModule ContactBigCTAButtonsModule`}>
    	<div className="col col16">
    		<div className="inner">
    			{props.data.buttons.map((ele, i) => (
					showLink[i] ? 
						<div key={i} className="btn black open" onMouseLeave={() => updateArray(i)}>
							<a target={ele.linktype == 'External' ? '_blank' : ''} href={ele.linktype == 'Phone' ? 'tel:'+ele.phone : ele.linktype == 'Email' ? 'mailto:'+ele.email : ele.linktype == 'External' ? ele.link : null}>
								<span className="btnlabel">{ele.label}</span>
								<span className="linklabel">
									{ele.linktype == 'Phone' ? ele.phone : ele.linktype == 'Email' ? ele.email : ele.linktype == 'External' ? ele.link_label : null}
								</span>
							</a>
						</div> 
					: 
						<div key={i} className="btn black" onMouseEnter={() => updateArray(i)}>{ele.label}</div>
				))}
    		</div>
		</div>
    </div>
  );
}

export default connect()(ContactBigCTAButtonsModule);
