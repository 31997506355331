import {store} from './configureStore'
import {getApiBase} from './../helpers'

export const configureGlobals = async () => {
	// disable default browser history scroll management
		if (window.history.scrollRestoration) {
		  window.history.scrollRestoration = 'manual';
		}

	// fetch options
		const response = await fetch(getApiBase() + '/wp-json/wp/v2/acf/options')
		const jsonResponse = await response.json();

		store.dispatch({
			type: 'SET_GLOBALS',
			data: jsonResponse
		})
}