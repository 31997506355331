import {store} from './configureStore'

export const configureScroll = () => {
	window.addEventListener('scroll', () => {
		store.dispatch({
			type: 'SET_SCROLL_POSITION',
			scrollPosition: window.pageYOffset
		})
	});

}