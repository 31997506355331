import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import { ReactComponent as ArrowIcon } from './../../../../assets/icons/arrowswungicon.svg'

function TextAndImageModule(props) {
	const imageIsHorizontal = props.data.image.width > props.data.image.height

	return (
		<div className={`PageModule TextAndImageModule ${props.data.layout}`}>
			<div className="col col16 title">
				<div className="inner">{props.data.title}</div>
			</div>
			<div className="col col16 breaker">
				<div className="inner" />
			</div>
			<div className={`col ${imageIsHorizontal ? 'col8' : 'col6'} image col16-s`}>
				<div className="inner">
					<LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.fullscreen} />
				</div>
			</div>
			<div className="col col8 text col16-s">
				<div className="inner">
					<h2 className="text_title">{props.data.psv_in_headline ? <span className='bold'>PSV </span> : ''}{props.data.headline}</h2>
					{props.data.tag ? <div className='black-tag'>{props.data.tag}</div> : null}
					<div dangerouslySetInnerHTML={{ __html: props.data.maintext }} />
					{props.data.make_link !== 'none' ?
						<div className="readmore_btn">
							{
								props.data.make_link == 'internal' ? <Link to={`/${props.data.link.post_name}`}>{props.data.link_label ? props.data.link_label : 'Read more'}<ArrowIcon /></Link>
									: props.data.make_link == 'external' ? <a href={props.data.external_url_link} target="">{props.data.link_label ? props.data.link_label : 'Read more'}<ArrowIcon /></a>
										: null
							}
						</div>
						: null}
				</div>
			</div>
		</div>
	);
}

export default connect()(TextAndImageModule);
