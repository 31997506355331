import React, {useEffect, useState} from 'react';
import './index.scss';

import {NavLink, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as MenuIcon} from './../../../assets/icons/plusicon.svg'

function TopMenu(props) {
	const [currentParrent, setCurrentParrent] = useState();

	useEffect(() => {
		if (props.menu) {
			let currentPathname = props.currentPathname.substr(1,99);
			setCurrentParrent(null)
			for (let i = 0; i < props.menu.mainmenu.length; i++) {
				let ele = props.menu.mainmenu[i];
				if (ele.menuitem_type == 'submenu') {
					for (let i2 = 0; i2 < ele.submenu_items.length; i2++) {
						let ele2 = ele.submenu_items[i2];
						if (ele2.post_name == currentPathname) {
							setCurrentParrent(ele.submenu_name);
						}
					}		
				}
			}
		}
	}, [props.currentPathname, props.menu])

	const location = useLocation();
    const TopMenuClass = () => {
		const hiddenPaths = ['/foundry', '/tech', '/deeptech', '/pitch', '/hafnium'];
		if (hiddenPaths.includes(location.pathname)) {
			return 'Topmenu hidden';
		} else {
			return 'Topmenu';
		}
    };

  return (
  		<div className={TopMenuClass()}>
	    	{props.menu ? 
				props.menu.mainmenu.map((ele, i) => (
					ele.menuitem_type === 'pagelink' ? 
		  	 			<div className="menuitem" key={i}><NavLink key={i} to={`/` + ele.menuitem_page[0].post_name}>{ele.menuitem_page[0].post_title}</NavLink></div>
		  	 		: 
	  	 				<div className={`menuitem${ele.submenu_name == currentParrent ? ' active' : ''}`} key={i}>
	  	 					{ele.submenu_name}
			  	 			<div className="submenu">
			  	 					{ele.submenu_items.map((ele2, i2) => (
			  	 							<NavLink key={i2} className="submenuitem" to={`/` + ele2.post_name}>{ele2.post_title}</NavLink>
			  	 						))}
			  	 			</div>
		  	 			</div>
					))
    		: null}
    	</div>
  );
}

export default connect((state) => ({
	menu: state.Globals.menu,
	currentPathname: state.router.location.pathname
}))(TopMenu)