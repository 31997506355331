import React from 'react'

import parse, { domToReact } from 'html-react-parser'

export function getApiBase() {
  // return process.env.NODE_ENV === 'production' ? 'https://cms.preseedventures.panditest.dk' :
  //     'https://cms.preseedventures.panditest.dk';
  return process.env.NODE_ENV === 'production' ? 'https://cms.preseedventures.dk' :
    'https://cms.preseedventures.dk';
}
export const convertHTMLStringToReact = (htmlString) => {
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return;

      if (attribs.href) {
        return <div>{domToReact(children, options)}</div>
      }
    }
  }

  return parse(htmlString, options)
}