import React from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { Link } from 'react-router-dom'

import LazyImageComponent from './../../../LazyImageComponent'

import { ReactComponent as ArrowIcon } from './../../../../../assets/icons/arrowswungicon.svg'

function ResourceArchiveSingle(props) {
    const { data } = props;
    console.log(data)
    let postLink = 'https://www.psvacademy.dk/resources/' + data.post_name;

    return (
        <div className={`col thumb show`} key={data.post_name}>
            <a href={postLink} target="_blank">
                <div className="inner">
                    {data.image_thumb ? <LazyImageComponent height={data.image_thumb.height} width={data.image_thumb.width} url={data.image_thumb.sizes.medium_large} /* link={postLink} */ /> : null}
                </div>
            </a>
            <a href={postLink} target="_blank">
                <div className="title"> {data.label ? <span className="label">{data.label}</span> : ''}{<span>{data.description} </span>} </div>
            </a>
            {/*       <ul className="tax">
                {data.filter ? data.filter.map((filterEle, filterIndex) => (
                    <a href={'https://www.psvacademy.dk/resources/?cat=' + filterEle.slug} target='_blank' key={filterIndex}>

                        <li key={filterIndex}>{filterEle.name}</li>
                    </a>
                )) : null}
            </ul> */}
            <a href={postLink} target="_blank">
                <div className="link"><ArrowIcon /></div>
            </a>
        </div>
    );
}

export default connect()(ResourceArchiveSingle);
