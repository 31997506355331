import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {MotionPathPlugin} from 'gsap/MotionPathPlugin'
import './index.scss';

import {ReactComponent as CircleIcon} from './../../../../assets/icons/circleicon.svg'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);


function PeopleStrategyAnimationModule(props) {
	const triggerRef = useRef(null);
	const peopleRef = useRef(null);
	const strategyRef = useRef(null);
	const arrowRef = useRef(null);

	var tl;

	const breakPoints = {
		desktop: 1200,
		medium: 800,
		small: 350
	}

	const motionPath = {
		people: {
			large: [{x:100, y:-220}, {x:320, y:-270}],
			medium: [{x:40, y:-150}, {x:230, y:-210}],
			small: [{x:-20, y:-84}, {x:0, y:-168}],
			xSmall: [{x:-10, y:-84}, {x:0, y:-138}]
		},
		strategy: {
			large: [{x:-100, y:220}, {x:-330, y:270}],
			medium: [{x:-40, y:150}, {x:-240, y:210}],
			small: [{x:20, y:84}, {x:0, y:160}],
			xSmall: [{x:10, y:84}, {x:0, y:138}]
		}
	}

	useEffect(() => {
		setTimeout(() => {
			// gsap.from(wrapperRef.current, {opacity: 0, y:100, duration: 1, scrollTrigger: })
			tl = gsap.timeline({
				scrollTrigger: {
					trigger: triggerRef.current,
					scrub: true,
					start: "bottom bottom+=50",
					end: "top top-=150",
				}
			})

			tl
			.from(peopleRef.current, {
				// motionPath: {path: [{x:360, y:250}, {x:600, y:0}, {x:300, y:-300}, {x:0, y:0}]},
				motionPath: {path: props.screenWidth > breakPoints.desktop ? motionPath.people.large : props.screenWidth > breakPoints.medium ? motionPath.people.medium : props.screenWidth > breakPoints.small ? motionPath.people.small : motionPath.people.xSmall},
				duration: 10
			})
			.from(strategyRef.current, {
				motionPath: {path: props.screenWidth > breakPoints.desktop ? motionPath.strategy.large : props.screenWidth > breakPoints.medium ? motionPath.strategy.medium : props.screenWidth > breakPoints.small ? motionPath.strategy.small : motionPath.strategy.xSmall},
				duration: 10}, 0)
			.from(arrowRef.current, {rotation: props.screenWidth > breakPoints.medium ? 90 : 40 , duration: 10}, 0)
		},200)
	}, [])

  return (
    <div className="PageModule PeopleStrategyAnimationModule" ref={triggerRef}>
        <div className="col col16">
        	<div className="inner">
        		<span ref={peopleRef} className="people">People</span>
        		<CircleIcon ref={arrowRef} className="circle"/>
        		<span ref={strategyRef}>Strategy</span>
    		</div>
    	</div>
    </div>
  );
}

export default connect(state => ({
	screenWidth: state.Device.size.width
}))(PeopleStrategyAnimationModule);
