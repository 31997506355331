import {store} from './configureStore'
import {getApiBase} from './../helpers'

export const configureRoutes = async () => {
  const response = await fetch(getApiBase() + '/wp-json/data/v2/routes')
  const jsonResponse = await response.json();

  store.dispatch({
    type: 'SET_ROUTES',
    data: jsonResponse
  })
}