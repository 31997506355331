import React from 'react';
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

function UntitledModule(props) {
  return (
    <div className="PageModule UntitledModule">
	    <div className="col col16 breaker">
        	<div className="inner">{props.data.name}</div>
        </div>
    	<div className="col col8 col16-s img">
    		<div className="inner">
    			{props.data.image ? <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.large}/> : null}
    			{props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
    		</div>
    	</div>
    	<div className="col col8 col16-s text">
    		<div className="inner">
    			{props.data.headline ? <h2 className="title">{props.data.headline}</h2> : null}
    			<div className="content" dangerouslySetInnerHTML={{__html: props.data.text}}/>
    			{props.data.link ? 
    				<div className="readmore_btn"><a href={props.data.link}>Read more<ArrowIcon/></a></div>
    				: null}
    		</div>
    	</div>
    </div>
  );
}

export default UntitledModule;
