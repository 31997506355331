const initialState = {
	routesHasBeenSet: false,
}

const Routes = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_ROUTES':
			return {
				...state,
				routesHasBeenSet: true,
				routes: action.data
			}
		break;

		default:
			return state
	}
}

export default Routes