import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';
import { getApiBase } from './../../../../helpers'




function Maintenance() {
    const [message, setMessage] = useState('')

    const fetchData = async () => {
        const response = await fetch(getApiBase() + '/wp-json/data/v2/normalpage?slug=maintenance-site');
        let jsonResponse = await response.json();
        console.log(jsonResponse)
        setMessage(jsonResponse);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className='maintenance-screen'>
            {message ? message.pagemodules.map((ele, i) => {
                return (
                    <p key={i}>{ele.message}</p>)
            }) : console.log()}
        </div>
    )

}

export default connect()(Maintenance);