import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import { Link, useLocation } from 'react-router-dom';

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowswungicon.svg'
import {animateScroll} from './../../actions';

const FooterEle = ({data}) => {
	const location = useLocation();

	// Hide FooterEle if specific URLs and data.page[0].post_name match the conditions
	if (
		(location.pathname === '/hafnium' && data.type === 'page' && data.page[0].post_name === 'sustainability-related-disclosures') ||
		(location.pathname === '/tech' && data.type === 'page' && data.page[0].post_name === 'psv-deeptech-sustainability-related-disclosures')
	) {
		return null; // Don't render anything
	}
  
	// Render the FooterEle component as usual
	return (
	  data.type === 'page' ? 
		<Link to={'/' + data.page[0].post_name}>{data.page[0].post_title}</Link> 
		: 
		<a href={data.link} target="_blank" rel="noopener noreferrer">{data.title}</a>
	);
  };

function Footer(props) {
	useEffect(() => {
		setTimeout(() => {
			window.hbspt.forms.create({
			    portalId: "5509946",
			    formId: "687ec352-82b4-4bd7-938e-fdc3a14d9b20",
			    target: '#hs_newsletter_target'
			});
		}, 200)
	}, [])

  return (
  	props.footer ? 
	    <div className="PageModule Footer">
    		<div className="col col16 breaker"><div className="inner"/></div>
	    	<div className="col col4 col16-l">
	    		<div className="inner">
	    			{props.footer.menu_col1 ? props.footer.menu_col1.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
	    				)) : null}
    			</div>
    		</div>
    		<div className="col col4 col16-l">
	    		<div className="inner">
	    			{props.footer.menu_col2 ? props.footer.menu_col2.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
	    				)) : null}
    			</div>
    		</div>
    		<div className="col col4 col16-l">
	    		<div className="inner">
	    			{props.footer.menu_col3 ? props.footer.menu_col3.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
	    				)) : null}
    			</div>
    		</div>

    		<div className="col col4 col16-l">
	    		<div className="inner">
	    			{props.footer.menu_col4 ? props.footer.menu_col4.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
	    				)) : null}
    			</div>
    		</div>
    		<div className="col col16">
    			<div className="inner newsletter">
    				<div className="above" dangerouslySetInnerHTML={{__html: props.footer.newsletter.above}}/>
	    			<div id="hs_newsletter_target" className="hs_form"/>
    				<div className="below" dangerouslySetInnerHTML={{__html: props.footer.newsletter.below}}/>
    			</div>
    		</div>
    		<div className="col col16">
    			<div className="inner address">
    				{props.footer.address ? 
    					props.footer.address.map((ele, i) => (
    						<span key={i}>{ele.line}</span>
    					))
    					: null
    				}
    			</div>
    		</div>
	    </div>
	: null
  );
}

export default connect(state => ({
	footer: state.Globals.footer
}))(Footer);
