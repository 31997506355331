import React from 'react';
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

const map = (value, x1, y1, x2, y2) => {
	return (
		value > x1 && value < y1 ? (value - x1) * (y2 - x2) / (y1 - x1) + x2
			: value > y1 || value === y1 ? y2
				: value < x1 || value === x1 ? x2
					: null
	)
}

function Mainlogo(props) {

	const location = useLocation();
    const getLogoText = () => {
		return 'PSV';
    };

    const getLogoExtension = () => {
        if (location.pathname === '/tech') {
            return 'Tech';
        } else if (location.pathname === '/deeptech') {
            return 'DeepTech';
        } else if (location.pathname === '/foundry') {
            return 'Foundry';
        } else if (location.pathname === '/hafnium') {
            return 'Hafnium';
        }
        
		return false;
    };
	
	return (
        <div id="Mainlogo" className={`${props.mainmenuIsOpen ? 'open' : ''}`}>
            <Link to="/" onClick={() => props.dispatch({ type: 'CLOSE_MAINMENU' })}>
                <span>{getLogoText()}</span>
				{getLogoExtension() ?
					<span class="logo-extension">{getLogoExtension()}</span>
				: '' }
            </Link>
        </div>
    );

	/*
	const letters = [
		{
			letter: 'P',
			animateY: false,
			animateOpacity: false,
			animateX: false
		},
		{
			letter: 'r',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'e',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'S',
			animateY: false,
			animateOpacity: false,
			animateX: true,
			destX: props.isMobileXSmallSize ? -26 : !props.isMobileSize ? -34 : -30,
			offset: 30
		},
		{
			letter: 'e',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'e',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'd',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'V',
			animateY: false,
			animateOpacity: false,
			animateX: true,
			destX: props.isMobileXSmallSize ? -88 : !props.isMobileSize ? -114 : -101,
			offset: 50
		},
		{
			letter: 'e',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'n',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 't',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'u',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'r',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 'e',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
		{
			letter: 's',
			animateY: true,
			animateOpacity: true,
			animateX: false
		},
	]
	*/

	/*
	return (
		<div id="Mainlogo" className={`${props.mainmenuIsOpen ? 'open' : ''}`}>
			<Link to="/" onClick={() => props.dispatch({ type: 'CLOSE_MAINMENU' })}>
				{letters.map((ele, id) => {
					let offset = {
						start: ele.animateX ? ele.offset : 0,
						end: ele.animateX ? ele.offset + 104 : 104
					}

					return (
						<span key={id} style={{
							transform: `translateY(-${ele.animateY ? map(props.scrollPosition, offset.start, offset.end, 0, 104) : 0}px) translateX(${ele.animateX ? map(props.scrollPosition, offset.start, offset.end, 0, ele.destX) : 0}px)`,
							opacity: ele.animateOpacity ? map(props.scrollPosition, offset.start, offset.end, 1, 1) : 1
						}}>
							{ele.letter}
						</span>
					)
				})}
			</Link>
		</div>
	);
	*/
}

export default connect(state => ({
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	scrollPosition: state.App.scrollPosition,
	isMobileSize: state.Device.isMobileSize,
	isMobileXSmallSize: state.Device.isMobileXSmallSize,
}))(Mainlogo);
