import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

function SidebarFetcher() {
  return (
    <div>
    	SidebarFetcher
    </div>
  );
}

export default connect()(SidebarFetcher);
