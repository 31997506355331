import React from 'react';
import {connect} from 'react-redux'
import './index.scss'

import {Link} from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

function CTASplitterModuleSmall(props) {
  return (
    <div className="PageModule CTASplitterModuleSmall">
        <div className="col col16 breaker">
            <div className="inner">{props.data.title}</div>
        </div>
        <div className="col col8">
        	<Link to={`/`+props.data.link.post_name}><h2 className="title">{props.data.headline}</h2></Link>
    	</div>
        <div className="col col8 btn-col">
                <Link to={`/`+props.data.link.post_name}><ArrowIcon/></Link>
        </div>
    </div>
  );
}

export default connect()(CTASplitterModuleSmall);
